import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import BlogOverview from "../../../components/blog/overview/blogOverview.mdx";
import AppImage from "../../../components/blog/appimage/appImage";
export const query = graphql`
  {
    mdx(slug: { eq: "calorie-calculator-favorite-food/" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_introduction {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_introduction_alt
        image_introduction_desc
        image_introduction_credit_text
        image_introduction_credit_link
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        image_fourth {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_fourth_alt
        date(formatString: "MMMM D, YYYY")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Consistently eating well and carefully recording what you eat is time-consuming and requires a lot of discipline.
  That's why it's even more important to have an app by your side that does the work for you and implements processes as efficiently and automatically as possible.
  With ShapeGanic, you have the option of tagging foods as favorites to make tracking even easier and faster so that you have more time for the important things in life.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_introduction)} imageAlt={props.data.mdx.frontmatter.image_introduction_alt} imageDesc={props.data.mdx.frontmatter.image_introduction_desc} imageAuthor={props.data.mdx.frontmatter.image_introduction_credit_text} imageLink={props.data.mdx.frontmatter.image_introduction_credit_link} mdxType="Image" />
    <h2>{`Your favorites at a glance`}</h2>
    <p>
  As shown in the following illustration, your favorites are clearly displayed in a list.
  To create a tracking entry from a favorite, you simply have to tap on it, enter the amount you have consumed or simply confirm if you have already used this food for tracking.
  If you want to delete a favorite, you have to press and hold the corresponding entry in the list and select the option to delete.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} mdxType="AppImage" />
    <h2>{`Mark foods as favorites`}</h2>
    <p>
  To mark a food as a favorite, tap on the corresponding entry to open the view shown below.
  If you now click on the heart symbol at the top right, the food will be added to your favorites list. Tap again to remove the entry.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} mdxType="AppImage" />
    <h2>{`Find favorites quickly`}</h2>
    <p>
  The image below shows the favorite results for a full-text search, which is updated after each keystroke.
  You will find the search mask at the top if you select the “Meal” tab. Your search results can also be sorted by calorie, protein, carbohydrate and fat content, which can also be seen in the following illustration.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} mdxType="AppImage" />
    <h2>{`Overview of foods used`}</h2>
    <p>
  Another special feature can be seen in the following illustration. Currently used foods are listed here.
  If you click on one of them, you will immediately be taken to an input screen where the last quantity used is listed.
  All you have to do is confirm and the meal entry is complete.
  If you have entered a portion size, you will alternatively be taken to a view where you can enter the number of portions.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_fourth)} imageAlt={props.data.mdx.frontmatter.image_fourth_alt} mdxType="AppImage" />
    <p>
  This function can be accessed via the “Meal” tab next to the search mask by clicking on the “History” option.
  To see this option, you may have to click on the overflow menu, which is symbolized by three dots.
  You will only see this option if there are already foods that you are currently using in tracking entries.
    </p>
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      